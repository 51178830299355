<template>
    <div>
        <v-container fluid v-if="placingOrder">
            <v-layout justify-center class="mt-5">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="228"></v-progress-circular>
            </v-layout>
            <v-layout justify-center class="mt-5">
                <h2 class="text-h2">{{$t('components.checkout.in-progress.title')}}</h2>
            </v-layout>
            <v-layout justify-center class="mt-5">
                <p>{{$t('components.checkout.in-progress.text')}}</p>
            </v-layout>
        </v-container>
        <v-container fluid v-else-if="thankYou">
            <v-layout justify-center class="mt-5">
                <v-progress-circular
                    color="wellis"
                    value="100"
                    size="228">
                    <v-icon color="wellis" size="128">mdi-check</v-icon>
                </v-progress-circular>
            </v-layout>
            <v-layout justify-center class="mt-5">
                <h2 class="text-h2">{{$t('components.checkout.thanks.title')}}</h2>
            </v-layout>
            <v-layout justify-center class="mt-5">
                <p>{{$t('components.checkout.thanks.text')}}</p>
            </v-layout>
        </v-container>
        <v-container fluid v-else>
            <v-overlay :value="loadingOverlay" absolute color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="7">
                    <h5 class="text-h5">{{$t('components.billing.title')}}</h5>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.billing.company')"
                                          :value="billingInfo.companyName"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.billing.vatNo')"
                                          :value="billingInfo.vatNo"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.checkout.email')"
                                          :value="accountData.email"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.billing.country')"
                                          :value="resolveCountry(billingInfo.countryCode)"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.billing.zip')"
                                          :value="billingInfo.zipCode"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-text-field color="wellis" readonly filled
                                          :label="$t('components.billing.city')"
                                          :value="billingInfo.city"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-textarea color="wellis" rows="3"
                                        :label="$t('components.billing.address')"
                                        readonly filled outlined
                                        :value="billingAddress"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <v-textarea color="wellis" rows="3"
                                        :label="$t('components.checkout.notes')"
                                        counter="200"
                                        outlined
                                        @input="trimNotes"
                                        v-model="checkout.notes"></v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6">
                            <h6 class="text-h6">{{$t('components.checkout.payments.title')}}</h6>
                            <v-btn class="ma-2"
                                   v-for="item in availablePaymentMethods" :key="item.key"
                                   large
                                   :color="selectedPaymentMethod === item.key ? 'wellis-text-white' : ''"
                                   @click="setPaymentMethod(item.key)">
                                {{$t('components.checkout.payments')[item.key]}}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" v-if="selectedPaymentMethod !== null">
                            <h6 class="text-h6">{{$t('components.checkout.shipping')}}</h6>
                            <v-btn class="ma-2"
                                   v-for="item in availableShippingMethods" :key="item.key"
                                   large
                                   :color="selectedShippingMethod === item.key ? 'wellis-text-white' : ''"
                                   @click="setShippingMethod(item.key)">
                                {{$t('components.checkout.shippingMethods')[item.key]}}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="selectedShippingMethod !== null">
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <h6 class="text-h6">{{$t('components.checkout.shippingAddress.title')}}</h6>
                                <v-btn class="ma-2"
                                       large
                                       :color="temporaryShippingAddress ? 'wellis-text-white' : ''"
                                       @click="setShippingAddressTemp(true)">
                                    {{$t('components.checkout.shippingAddress.temp.title')}}
                                </v-btn>
                                <v-btn class="ma-2"
                                       large
                                       :color="!temporaryShippingAddress ? 'wellis-text-white' : ''"
                                       @click="setShippingAddressTemp(false)">
                                    {{$t('components.checkout.shippingAddress.exists.title')}}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" v-if="temporaryShippingAddress">
                                <v-row class="mt-2" v-if="checkout.shippingAddress.temporaryAddress.zip !== null">
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <v-chip color="wellis-text-white" close @click:close="setShippingAddressTemp(true)">
                                            {{temporaryShippingAddressString}}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-2" v-else>
                                    <v-col cols="12" sm="12" md="12" lg="6">
                                        <v-text-field color="wellis"
                                                      :error="tempFormErrors.zip"
                                                      clearable
                                                      :error-messages="tempFormErrors.zip"
                                                      :label="$t('components.billing.zip')"
                                                      v-model="tempForm.zip"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="6">
                                        <v-text-field color="wellis"
                                                      :error="tempFormErrors.city"
                                                      clearable
                                                      :error-messages="tempFormErrors.city"
                                                      :label="$t('components.billing.city')"
                                                      v-model="tempForm.city"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <v-text-field color="wellis" :error="tempFormErrors.address1" counter="35"
                                                      :error-messages="tempFormErrors.address1"
                                                      clearable
                                                      :label="$t('components.checkout.shippingAddress.temp.address1')"
                                                      v-model="tempForm.address1"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <v-text-field color="wellis" :error="tempFormErrors.address2"
                                                      counter="35"
                                                      clearable
                                                      :error-messages="tempFormErrors.address2"
                                                      :label="$t('components.checkout.shippingAddress.temp.address2')"
                                                      v-model="tempForm.address2"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <v-btn block @click="finalizeTemporaryAddress">{{$t('btnSave')}}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" lg="12" v-else>
                                <v-select
                                    :placeholder="$t('components.checkout.shippingAddress.exists.select')"
                                    color="black"
                                    item-color="wellis"
                                    :items="Object.values(shippingAddresses)"
                                    item-value="addressId"
                                    v-model="checkout.shippingAddress.addressId"
                                    @change="scrollDown"
                                >
                                    <template v-slot:item="{ item }">
                                        {{`${item.zipCode}, ${item.city} ${item.primaryAddressLine} ${item.secondaryAddressLine || ''}`}}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        {{`${item.zipCode}, ${item.city} ${item.primaryAddressLine} ${item.secondaryAddressLine || ''}`}}
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="hasShippingServices">
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <h6 class="text-h6">{{$t('components.checkout.select-shipping-service')}}</h6>
                                <v-chip class="ma-2"
                                        v-for="(item, index) in shippingServices" :key="index"
                                        large
                                        :color="selectedShippingService === index ? 'wellis-text-white' : ''"
                                        @click="setShippingService(index)">
                                    {{item.name}}
                                    <br>
                                    {{item.price.unitPrice | toPrice(item.price.currency, item.price.vat)}}
                                </v-chip>
                            </v-col>
                        </v-row>
                    </div>
                    <v-container class="mt-3 text-center" v-if="orderReady">
                        <v-btn large color="wellis-text-white" @click="performCheckout">{{$t('components.checkout.btnPlaceOrder')}}</v-btn>
                    </v-container>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="5">
                    <h5 class="text-h5">{{$t('components.cart.title')}}</h5>
                    <v-list three-line class="cart-items">
                        <template v-for="(item, index) in cart">
                            <CheckoutItem :cart-item="item" :key="'ci_'+index+'_'+$store.getters['langStore/current']"/>
                            <CheckoutItem :cart-item="subItem"
                                          v-for="(subItem, subIndex) in item.children"
                                          :key="'ci_sub_'+item.id+'_'+subIndex+'_'+$store.getters['langStore/current']"
                                          :is-child="true"/>
                        </template>
                    </v-list>
                    <v-card color="transparent2 mb-5" v-if="hasPrice">
                        <div class="card-title total-title">
                            <v-layout justify-center>
                                <div v-if="hasVat" class="font-weight-light">
                                    <span class="wellis--text font-weight-bold mr-2">{{$t('components.cart.net') | toUpper}}</span> {{total | toPrice(currency, false)}}
                                </div>
                                <v-icon v-if="hasVat" class="ml-6 mr-6">mdi-power-on</v-icon>
                                <div class="font-weight-light">
                                    {{total | toPrice(currency, hasVat)}} <span class="ml-2 wellis--text font-weight-bold">{{$t('components.cart.gross') | toUpper}}</span>
                                </div>
                            </v-layout>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN, EVENT_CART_COUNT_UPDATE, EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import CheckoutApiService from '../../../api/checkout.api';
import {__clone, empty, LiteUser} from '../../../helpers';
import CheckoutItem from './CheckoutItem';
import Vue from 'vue';
import FaultReportApi from '../../../api/fault-report.api';

export default {
    name: 'CheckoutView',
    components: {CheckoutItem},

    mounted() {
        Vue.prototype.$CheckoutView = this;

        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.checkout.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'place_order',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                },
            });
            return;
        }

        this.$eventBus.$on(CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN, data => {
            let cartItem = this.cart.find(x => x.id === data.cartItemId);

            if (data.prevMsDate !== null && data.msDate !== data.prevMsDate) {
                this.customerForecast[data.catalogNo][data.prevMsDate] += cartItem.quantity;
            }

            this.$nextTick(() => {
                this.REFRESH_KEY++;
                this.$set(this.forecast, 'record_' + data.cartItemId, data.msDate);
                this.customerForecast[data.catalogNo][data.msDate] -= cartItem.quantity;
            });

        });

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.fetch();
        });

        this.fetch();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
        this.$eventBus.$off(CHECKOUT_MASTER_SCHEDULE_CHANGE_MAIN);
    },

    data() {
        return {
            loadingOverlay: false,

            REFRESH_KEY: 0,

            billing: {},
            account: {},
            shipping: [],
            paymentMethods: [],
            shippingMethods: [],
            shippingServices: false,
            cart: [],

            tempForm: {
                zip: null,
                city: null,
                address1: null,
                address2: null,
            },

            tempFormErrors: {
                zip: null,
                city: null,
                address1: null,
                address2: null,
            },

            checkout: {
                selectedPaymentMethod: null,
                selectedShippingMethod: null,
                shippingAddress: this.defaultShippingAddressObj(),
                shippingService: null,
                notes: null,
            },

            orderInProgress: false,
            orderComplete: false,

            forecast: {},
            customerForecast: {},
            countries: [],
        };
    },

    methods: {

        scrollDown() {
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 500);
        },

        async getCountries() {
            await FaultReportApi.getCountries().then(response => this.countries = response.data);
        },

        defaultShippingAddressObj() {
            return {
                addressId: null,
                temporary: false,
                temporaryAddress: {
                    zip: null,
                    city: null,
                    address1: null,
                    address2: null,
                },
            };
        },

        async fetch() {
            this.loadingOverlay = true;
            await this.getCountries();
            CheckoutApiService.getPreviewData()
                .then(async response => {
                    this.billing = response.data.billing;
                    this.shipping = response.data.shipping;
                    this.account = response.data.account;

                    if (this.$store.getters['authStore/isLiteUser']) {
                        this.account.email = this.$store.getters['authStore/getLiteUser'].notify_email;
                    }

                    this.paymentMethods = response.data.paymentMethods;
                    this.cart = response.data.cart;
                    this.cart.forEach(item => {
                        if (item.type === 'PRODUCT' && typeof item.configuration === 'object'
                            && (item.catalogNo.toString().startsWith('WM') || item.catalogNo.toString().startsWith('WU'))) {
                            this.forecast['record_' + item.id] = null;

                            this.customerForecast[item.catalogNo] = {};
                            CheckoutApiService.masterScheduleDates(item.catalogNo, item.quantity)
                                .then(response => {
                                    Object.values(response.data).forEach(item_ => {
                                        this.customerForecast[item.catalogNo][item_.ms_date] = item_.qty;
                                    });
                                });
                        }
                    });

                    if (this.availablePaymentMethods.length === 1) {
                        this.setPaymentMethod(this.availablePaymentMethods[0].key);
                    }
                })
                .catch(() => {
                    this.$router.push({
                        name: 'app.cart',
                    });
                })
                .finally(() => this.loadingOverlay = false);
        },

        setPaymentMethod(method) {
            this.checkout.selectedShippingMethod = null;
            this.shippingServices = false;
            this.checkout.shippingService = null;
            if (this.checkout.selectedPaymentMethod === method) {
                this.checkout.selectedPaymentMethod = null;
                this.scrollDown();
            } else {
                this.loadingOverlay = true;
                CheckoutApiService.getShippingMethods(method)
                    .then(response => {
                        this.checkout.selectedPaymentMethod = method;
                        this.shippingMethods = response.data;

                        if (this.availableShippingMethods.length === 1) {
                            this.setShippingMethod(this.availableShippingMethods[0].key);
                        }
                        this.scrollDown();
                    })
                    .finally(() => this.loadingOverlay = false);
            }
        },

        setShippingMethod(method) {
            this.checkout.shippingAddress = this.defaultShippingAddressObj();
            if (this.checkout.selectedShippingMethod === method) {
                this.checkout.selectedShippingMethod = null;
            } else {
                this.checkout.selectedShippingMethod = method;
            }
            this.shippingServices = false;
            this.checkout.shippingService = null;
            this.scrollDown();
        },

        setShippingAddressTemp(value) {
            this.checkout.shippingAddress = this.defaultShippingAddressObj();
            this.checkout.shippingAddress.temporary = value;
            this.shippingServices = false;
            this.checkout.shippingService = null;
            this.scrollDown();
        },

        validateShippingAddress(data) {
            return new Promise((resolve, reject) => {
                const errors = [];

                if (this.checkout.shippingAddress.temporary) {
                    if (empty(data.zip)) {
                        errors.push(this.$t('validation.required', {field: this.$t('components.billing.zip')}));
                        this.tempFormErrors.zip = this.$t('validation.required', {field: this.$t('components.billing.zip')});
                    } /*else {
                        if (isNaN(data.zip)) {
                            errors.push(this.$t('validation.numeric', {field: this.$t('components.billing.zip')}));
                            this.tempFormErrors.zip = this.$t('validation.numeric', {field: this.$t('components.billing.zip')});
                        }
                    }*/

                    if (empty(data.city)) {
                        errors.push(this.$t('validation.required', {field: this.$t('components.billing.city')}));
                        this.tempFormErrors.city = this.$t('validation.required', {field: this.$t('components.billing.city')});
                    }

                    if (empty(data.address1)) {
                        errors.push(this.$t('validation.required', {field: this.$t('components.checkout.shippingAddress.temp.address1')}));
                        this.tempFormErrors.address1 = this.$t('validation.required', {field: this.$t('components.checkout.shippingAddress.temp.address1')});
                    } else {
                        if (data.address1.length > 35) {
                            errors.push(this.$t('validation.maxlength', {field: this.$t('components.checkout.shippingAddress.temp.address1'), num: 35}));
                            this.tempFormErrors.address1 = this.$t('validation.maxlength', {field: this.$t('components.checkout.shippingAddress.temp.address1'), num: 35});
                        }
                    }

                    if (!empty(data.address2)) {
                        if (data.address2.length > 35) {
                            errors.push(this.$t('validation.maxlength', {field: this.$t('components.checkout.shippingAddress.temp.address2'), num: 35}));
                            this.tempFormErrors.address2 = this.$t('validation.maxlength', {field: this.$t('components.checkout.shippingAddress.temp.address2'), num: 35});
                        }
                    }
                }

                if (errors.length > 0) {
                    return reject(errors);
                }

                return resolve(data);
            });
        },

        finalizeTemporaryAddress() {
            this.validateShippingAddress(this.tempForm)
                .then(() => {
                    this.checkout.shippingAddress.temporaryAddress = __clone(this.tempForm);
                    this.tempForm = {
                        zip: null,
                        city: null,
                        address1: null,
                        address2: null,
                    };
                    this.scrollDown();
                });
        },

        setShippingService(index) {
            if (this.checkout.shippingService === index) {
                this.checkout.shippingService = null;
            } else {
                this.checkout.shippingService = index;
            }
            this.scrollDown();
        },

        performCheckout() {
            this.validateShippingAddress(this.checkout.shippingAddress.temporaryAddress)
                .then(() => {
                    this.orderInProgress = true;
                    let data = this.checkout;
                    if (data.shippingService) {
                        data.shippingService = this.shippingServices[data.shippingService].code;
                    }
                    data.forecast = this.forecast;
                    CheckoutApiService.checkout(data)
                        .then(() => {
                            this.orderComplete = true;
                            this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, 0);
                        })
                        .catch(() => {
                            this.$snackBar({
                                content: this.$t('whoops'),
                                icon: 'mdi-alert',
                            });
                        })
                        .finally(() => this.orderInProgress = false);
                })
                .catch(errors => {
                    this.$snackBar({
                        content: errors.join('<br>'),
                        icon: 'mdi-alert',
                    });
                });
        },

        trimNotes() {
            if (this.checkout.notes !== null && this.checkout.notes.length > 200) {
                this.$nextTick(() => {
                    this.$set(this.checkout, 'notes', this.checkout.notes.substr(0, 200));
                });
            }
        },
    },

    computed: {
        countCartItems() {
            let i = this.cart.length;

            this.cart.map(item => {
                i += item.children.length;
            });

            return i;
        },

        cartItems() {
            return this.cart;
        },

        billingInfo() {
            return this.billing;
        },

        availablePaymentMethods() {
            let methods = [];
            Object.keys(this.paymentMethods).map(item => {
                if (this.paymentMethods[item] === true) {
                    methods.push({
                        key: item,
                    });
                }
            });

            return methods;
        },

        availableShippingMethods() {
            let methods = [];
            Object.keys(this.shippingMethods).map(item => {
                if (this.shippingMethods[item] === true) {
                    methods.push({
                        key: item,
                    });
                }
            });

            return methods;
        },

        shippingAddresses() {
            return this.shipping;
        },

        accountData() {
            return this.account;
        },

        total() {
            if (this.cart.length === 0 || this.cart[0].price === null) {
                return 0;
            }
            let total = 0;
            this.cart.map(item => {
                total += item.price.unitPrice * item.quantity;
                item.children.map(x => total += x.price.unitPrice * x.quantity);
            });

            return total;
        },


        hasPrice() {
            return !(this.cart.length === 0 || this.cart[0].price === null);
        },

        currency() {
            if (this.cart.length === 0 || this.cart[0].price === null) {
                return 'N/A';
            }
            if (this.cart.length > 0) {
                return this.cart[0].price.currency || 'N/A';
            }

            return 'HUF';
        },

        hasVat() {
            if (this.cart.length === 0 || this.cart[0].price === null) {
                return false;
            }
            if (this.cart.length > 0) {
                return this.cart[0].price.vat || false;
            }

            return false;
        },

        billingAddress() {
            return this.billingInfo.address1 + '\n' + (this.billingInfo.address2 || '');
        },

        selectedPaymentMethod() {
            return this.checkout.selectedPaymentMethod;
        },

        selectedShippingMethod() {
            return this.checkout.selectedShippingMethod;
        },

        temporaryShippingAddress() {
            return this.checkout.shippingAddress.temporary;
        },

        temporaryShippingAddressString() {
            return `${this.checkout.shippingAddress.temporaryAddress.zip} ${this.checkout.shippingAddress.temporaryAddress.city}`
                + ` ${this.checkout.shippingAddress.temporaryAddress.address1} ${this.checkout.shippingAddress.temporaryAddress.address2 || ''}`;
        },

        isTemporaryAddressFilled() {
            return this.checkout.shippingAddress.temporaryAddress.zip !== null
                && this.checkout.shippingAddress.temporaryAddress.city !== null
                && this.checkout.shippingAddress.temporaryAddress.address1 !== null;
        },

        orderReady() {
            let a = !empty(this.checkout.selectedPaymentMethod)
                && !empty(this.checkout.selectedShippingMethod)
                && (this.checkout.shippingAddress.temporary
                    ? (!empty(this.checkout.shippingAddress.temporaryAddress.zip)
                        && !empty(this.checkout.shippingAddress.temporaryAddress.city)
                        && !empty(this.checkout.shippingAddress.temporaryAddress.address1)
                    )
                    : !empty(this.checkout.shippingAddress.addressId));

            let b = false;
            if (this.account.custGroup === 'NAGYKER') {
                if (this.checkout.selectedPaymentMethod === 'CARD-ONLINE' && this.checkout.shippingAddress.temporary) {
                    b = this.shippingServices.length > 0 && !empty(this.checkout.shippingService);
                } else {
                    b = true;
                }
            } else {
                if (this.checkout.selectedPaymentMethod === 'CARD-ONLINE') {
                    b = this.shippingServices.length > 0 && !empty(this.checkout.shippingService);
                } else {
                    b = true;
                }
            }

            return a && b && this.wantedDeliveryDatesReady;
        },

        thankYou() {
            return this.orderComplete === true;
        },

        placingOrder() {
            return this.orderInProgress === true;
        },

        hasShippingServices() {
            return this.shippingServices instanceof Array && this.shippingServices.length > 0;
        },

        selectedShippingService() {
            return this.checkout.shippingService;
        },

        wantedDeliveryDatesReady() {
            this.REFRESH_KEY;
            let i = 0;

            Object.values(this.forecast).forEach(item => {
                if (empty(item)) {
                    i++;
                }
            });
            return i === 0;
        },

        resolveCountry() {
            return countryCode => {
                let country = this.countries.find(item => item.country_code === countryCode);
                return country ? country.name : countryCode;
            };
        },
    },
};
</script>

<style scoped>
.cart-items {
    max-height: 650px;
    overflow-y: scroll;
}

@media (max-width: 768px) {
    .cart-items {
        max-height: unset;
        overflow-y: unset;
    }
}

.total-title {
    font-size: 20px !important;
}

@media screen and (max-width: 700px) {
    .total-title {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 400px) {
    .total-title {
        font-size: 9px !important;
    }
}
</style>
