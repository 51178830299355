<template>
    <div>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container v-else>
            <v-overlay :value="loadingOverlay" color="white">
                <v-progress-circular
                    color="wellis"
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
            <v-row>
                <v-col cols="12" md="12" sm="12">
                    <v-card v-for="(shipment, index) in shipments" :key="`${shipment.pro_no}_${index}`" class="mb-8">
                        <v-card-title>
                            <img :src="require('../../../assets/img/logo.svg')" width="186">
                        </v-card-title>
                        <v-card-text style="font-size:15px">
                            <v-divider class="mb-3"></v-divider>
                            <v-row>
                                <v-col cols="12" xl="4" sm="12">
                                    <h3 class="mb-3" style="color:transparent">-</h3>
                                    <div>
                                        <span class="font-weight-bold">{{$t('components.my-shipments.loadingId') | capitalize}}: </span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{shipment.pro_no}}</span>
                                    </div>

                                    <div class="mt-2">
                                        <span class="font-weight-bold">{{$t('components.my-shipments.shipmentId') | capitalize}}: </span>
                                        <span class="wellis--text ml-1 font-weight-bold">{{shipment.shipment_id}}</span>
                                    </div>
                                </v-col>

                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" sm="12">
                                    <v-simple-table>
                                        <thead>
                                        <tr>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.my-shipments.details.loadingDate') | capitalize}}</th>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.my-orders.details.customer') | capitalize}}</th>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.billing.country') | capitalize}}</th>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.billing.zip') | capitalize}}</th>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.billing.city') | capitalize}}</th>
                                            <th class="text-uppercase small font-weight-bold">{{$t('components.billing.address') | capitalize}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>{{shipment.ship_date | formatDate}}</td>
                                            <td class="wellis--text font-weight-bold">{{shipment.customer_address_name}}</td>
                                            <td>{{shipment.customer_country}}</td>
                                            <td>{{shipment.customer_zip_code}}</td>
                                            <td>{{shipment.customer_city}}</td>
                                            <td>{{`${shipment.customer_address1} ${shipment.customer_address2 || ''}`}}</td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-divider class="mt-3 mb-3"></v-divider>

                            <v-row class="mt-3">
                                <v-col cols="12" sm="12">
                                    <h4 class="mb-3">{{$t('components.products.title')}}</h4>
                                    <v-simple-table>
                                        <thead>
                                        <tr>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('v3.components.my-orders.orderNo') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.products.catalogNo') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-orders.catalogDesc') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.stocklist.serialNo') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.stocklist.configNo') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-shipments.details.shippedQuantity') | capitalize}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-orders.customerPo') | capitalize}}</th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="shipment.lines === null">
                                        <tr>
                                            <td colspan="7">
                                                <v-progress-linear indeterminate
                                                                   color="wellis"
                                                ></v-progress-linear>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody v-else-if="shipment.lines !== null && shipment.lines.length === 0">
                                        <tr>
                                            <td colspan="7" class="wellis--text">
                                                {{$t('components.my-orders.details.noTableData')}}
                                            </td>
                                        </tr>
                                        </tbody>
                                        <tbody>
                                        <tr v-for="item in shipment.lines" :key="`${item.order_no}_${item.line_no}_${item.rel_no}`">
                                            <td>
                                                <v-btn text class="font-weight-bold" color="wellis" :to="{name: 'app.account.orders.details', params: {orderNo: item.order_no}}">
                                                    {{`${item.order_no} / ${item.rel_no} / ${item.line_no}`}}
                                                </v-btn>
                                            </td>
                                            <td class="wellis--text font-weight-bold">{{item.catalog_no}}</td>
                                            <td class="wellis--text">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-on="on" v-bind="attrs" class="wellis--text">{{item.catalog_desc | cut(20)}}</span>
                                                    </template>
                                                    <span>{{item.catalog_desc}}</span>
                                                </v-tooltip>
                                            </td>
                                            <td>{{item.serial_no}}</td>
                                            <td>
                                                <v-btn text v-if="item.configuration_id !== '*' && item.configuration_id !== null"
                                                       @click="getConfiguration(item.catalog_no, item.part_no, item.configuration_id)">
                                                    <v-icon>mdi-magnify</v-icon>
                                                    {{item.configuration_id}}
                                                </v-btn>
                                                <v-btn text v-else>
                                                    <v-icon style="color:transparent">mdi-magnify</v-icon>
                                                    {{item.configuration_id}}
                                                </v-btn>
                                            </td>
                                            <td>{{item.shipped_qty}}</td>
                                            <td>{{item.c_customer_po_no}}</td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>

                            <v-row class="mt-3">
                                <v-col cols="12" sm="12">
                                    <h4 class="mb-3">{{$t('components.my-shipments.details.documents')}}</h4>
                                    <v-simple-table>
                                        <thead>
                                        <tr>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-invoices.typeInvoice')}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-invoices.typeCmr')}}</th>
                                            <th class="border-0 text-uppercase small font-weight-bold">{{$t('components.my-invoices.typeDeliveryNote')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                <v-btn small text :color="shipment.invoice === null ? '' : 'wellis'"
                                                       :disabled="shipment.invoice === null || !hasLiteUserPermission('my_invoices')"
                                                       @click="downloadDocument(shipment.invoice)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn small text :color="shipment.cmr === null ? '' : 'wellis'"
                                                       :disabled="shipment.cmr === null  || !hasLiteUserPermission('my_invoices')"
                                                       @click="downloadDocument(shipment.cmr)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                            <td>
                                                <v-btn small text :color="shipment.szall === null ? '' : 'wellis'"
                                                       :disabled="shipment.szall === null  || !hasLiteUserPermission('my_invoices')"
                                                       @click="downloadDocument(shipment.szall)">
                                                    <v-icon>mdi-download</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
            v-model="configurationDialog"
            max-width="650px"
            @click:outside="configurationDialog = false"
        >
            <v-card>
                <v-card-title>
                    {{$t('v3.components.cart.btnViewConfig')}}
                </v-card-title>
                <v-card-text v-html="configurationData || ''">
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE} from '../../../core/constants';
import AccountApiService from '../../../api/account.api';
import ConfiguratorApiService from '../../../api/configurator.api.service';
import {handleDownloadedFile, nl2br} from '../../../helpers';
import MediaApiService from '../../../api/media.api';

export default {
    name: 'MyLoadingDetails',

    mounted() {
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.my-shipments.title', this.$route.params.loadingId), 100);

        if (!this.hasLiteUserPermission('my_shipments')) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: function() {
                    this.$router.push({
                        name: 'app.start',
                    });
                },
            });
            return;
        }

        this.fetch();
        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.fetch();
        });
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: false,

            configurationDialog: false,
            configurationData: null,

            itemsLoading: false,
            documentsLoading: false,

            configCache: {},
            shipments: [],
        };
    },

    methods: {
        hasLiteUserPermission(permission) {
            if (!this.$store.getters['authStore/isLiteUser']) {
                return true;
            }
            let permission_ = this.$store.getters['authStore/getLiteUserPermission'].find(x => x.permission === permission);
            return permission_.objstate === 'ACTIVE';
        },

        fetch() {
            this.loadingOverlay = true;
            AccountApiService.loadings.shipments(this.$route.params.loadingId)
                .then(response => {
                    if (response.data !== null && response.data instanceof Array) {
                        this.shipments = [];
                        response.data.forEach(item => {
                            item.lines = null;
                            item.invoices = null;
                            this.shipments.push(item);
                        });
                        this.getShipmentLines();
                    }
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        text: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.mainOverlay = false;
                    this.loadingOverlay = false;
                });
        },

        async getShipmentLines() {
            if (this.shipments !== null && this.shipments instanceof Array) {
                this.shipments.forEach((item, index) => {
                    AccountApiService.loadings.shipmentLines(item.shipment_id)
                        .then(response => this.shipments[index].lines = response.data);
                });
            }
        },

        nl2br(string) {
            return nl2br(string);
        },

        getConfiguration(catalogNo, partNo, configNo) {
            if (configNo !== '*' && configNo !== null) {
                const cacheKey = `${catalogNo}_${partNo}_${configNo}_${this.$store.getters['langStore/current']}`;
                this.loadingOverlay = true;
                if (cacheKey in this.configCache) {
                    this.viewConfiguration(this.configCache[cacheKey]);
                } else {
                    ConfiguratorApiService.getConfiguration(configNo)
                        .then(response => {
                            this.configCache[cacheKey] = response.data.configuration;
                            this.viewConfiguration(this.configCache[cacheKey]);
                        })
                        .catch(() => {
                            this.$snackBar({
                                icon: 'mdi-alert',
                                allowOutsideClick: false,
                                text: this.$t('api-error'),
                            });
                            this.loadingOverlay = false;
                        });
                }
            }
        },

        viewConfiguration(data) {
            let body = '<div class="v-data-table theme--light"><div class="v-data-table__wrapper"><table>'
                + '<tbody>';

            data.forEach((item) => {
                body += `<tr><td class="font-weight-bold">${item.characteristic_id}</td><td class="wellis--text font-weight-bold">${item.characteristic_value}</td></tr>`;
            });

            body += '</div></div>';
            this.configurationData = body;
            this.configurationDialog = true;
            this.loadingOverlay = false;
        },

        downloadDocument(documentId) {
            this.loadingOverlay = true;
            MediaApiService.ifs.downloadDocument(documentId)
                .then(blobFile => {
                    return handleDownloadedFile(blobFile.data, blobFile.headers['content-disposition'].replace('attachment; filename=', ''), blobFile.type);
                })
                .catch(() => {
                    this.$snackBar({
                        icon: 'mdi-alert',
                        allowOutsideClick: false,
                        text: this.$t('api-error'),
                    });
                })
                .finally(() => {
                    this.loadingOverlay = false;
                });
        },
    },
};
</script>

<style scoped>

</style>
