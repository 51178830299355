<template>
    <v-container fluid>
        <v-container fluid class="mt-10" v-if="mainOverlay">
            <v-progress-linear indeterminate
                               color="wellis"
            ></v-progress-linear>
        </v-container>
        <v-container fluid v-else>
            <v-row>
                <v-col cols="12" xl="3" lg="4" md="12" sm="12">
                    <v-card>
                        <v-card-title>{{$t('components.stocklist.filters')}}</v-card-title>
                        <v-card-subtitle>
                            <v-btn block @click="clearFilters">
                                {{$t('components.stocklist.btnClearFilters')}}
                            </v-btn>
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-card-text>
                            <!-- Product filters -->
                            <v-card color="transparent2">
                                <v-card-title>{{$t('components.stocklist.filterProducts')}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(item, index) in productFilters"
                                                class="my-0"
                                                :key="index"
                                                v-model="item.checked"
                                                :label="item.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of product filters -->

                            <!-- Need week filters -->
                            <v-card color="transparent2">
                                <v-card-title>{{$t('components.shopord.needweek') | capitalize}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(item, index) in readinessFilters"
                                                class="my-0"
                                                :key="index"
                                                v-model="item.checked"
                                                :label="item.value !== 'NOW'
                                                ? $t('components.shopord.readyWeek', {num: item.value})
                                                : $t('components.stock-mixed.available-now')"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of need week filters -->

                            <!-- Warehouse filters -->
                            <v-card color="transparent2">
                                <v-card-title>{{$t('components.stocklist.warehouse')}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(item, index) in warehouseFilters"
                                                class="my-0"
                                                :key="index"
                                                v-model="item.checked"
                                                :label="item.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of warehouse filters -->

                            <!-- Characteristic filters -->
                            <v-card color="transparent2" v-for="(item, index) in characteristicFilters" :key="index">
                                <v-card-title>{{item.name}}</v-card-title>
                                <v-card-text style="max-height: 350px; overflow-y: scroll">
                                    <v-checkbox v-for="(characteristic, index2) in item.values" :key="index2"
                                                class="my-0"
                                                v-model="characteristic.checked"
                                                :label="characteristic.name"
                                                color="wellis"
                                    ></v-checkbox>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn block @click="applyFilters">
                                        {{$t('components.stocklist.btnApplyFilters')}}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                            <!-- End of warehouse filters -->
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" xl="9" lg="8" md="12" sm="12">
                    <v-overlay :value="loadingOverlay" color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-card color="transparent2">
                        <v-card-title>
                            <v-text-field clearable color="wellis" v-model="quickSearch" :label="$t('components.topbar.search')"></v-text-field>
                        </v-card-title>
                        <v-card-subtitle>{{$t('components.stocklist.dataLength', {len: dataLength, time: fetchTimeComputed})}}</v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="12" md="6" lg="4" xl="3" v-for="item in filteredResults"
                                       :key="item.name+'_'+(Object.values(item.stocklist).length+item.shopord.length)">
                                    <SaleProductCard :product="item" :disable-buttons="disableButtons"/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import {EVENT_LANG_CHANGE, EVENT_PAGE_TITLE_CHANGE, EVENT_SALES_VIEW_DISABLE_BTN, EVENT_SALES_VIEW_ENABLE_BTN} from "../../../core/constants";
import {__clone, empty, LiteUser} from '../../../helpers';
import StockMixedApiService from "../../../api/stock-mixed.api";
import SaleProductCard from "./SaleProductCard";
import Vue from "vue";
import PurchaseOrderApi from "../../../api/purchase-order.api";

export default {
    name: "SaleView",
    components: {SaleProductCard},
    mounted() {
        Vue.prototype.$SaleView = this;
        setTimeout(() => this.$eventBus.$emit(EVENT_PAGE_TITLE_CHANGE, 'components.stock-mixed.title'), 100);

        if (!LiteUser.hasPermission(
            this.$store.getters['authStore/isLiteUser'],
            this.$store.getters['authStore/getLiteUserPermission'],
            'products',
        )) {
            this.$ApplGeneral({
                content: this.$t('v32.liteUser.hasNoPermission'),
                onClose: () => {
                    this.$router.push({name: 'app.start'});
                }
            });
            return;
        }

        this.$eventBus.$on(EVENT_LANG_CHANGE, () => {
            this.bootstrap();
        });

        this.$eventBus.$on(EVENT_SALES_VIEW_DISABLE_BTN, () => this.disableButtons = true);
        this.$eventBus.$on(EVENT_SALES_VIEW_ENABLE_BTN, () => this.disableButtons = false);

        this.bootstrap();
    },

    destroyed() {
        this.$eventBus.$off(EVENT_LANG_CHANGE);
        this.$eventBus.$off(EVENT_SALES_VIEW_DISABLE_BTN);
        this.$eventBus.$off(EVENT_SALES_VIEW_ENABLE_BTN);
    },

    data() {
        return {
            mainOverlay: true,
            loadingOverlay: true,
            stock: [],
            quickSearch: null,
            filters: {
                category: [],
                product: [],
                warehouse: [],
                readiness: [],
                characteristics: []
            },
            fetchTime: 0,
            queryFiltering: false,
            queryFilters: {
                category: [],
                product: [],
                warehouse: [],
                readiness: [],
                characteristics: []
            },

            disableButtons: false,
        }
    },

    methods: {
        bootstrap(filters = null) {
            return new Promise((resolve) => {
                this.loadingOverlay = true;
                let fetchStartTime = Date.now();
                StockMixedApiService.getStock(filters)
                    .then(response => {
                        this.$set(this, 'stock', Object.values(response.data.stock));
                        if (filters === null) {
                            this.filters.product = response.data.filters.product;
                            this.filters.warehouse = response.data.filters.warehouse;
                            this.filters.readiness = [{
                                value: "NOW",
                                name: "now",
                                checked: false
                            }, ...response.data.filters.readiness];
                            this.filters.characteristics = Object.values(response.data.filters.characteristics);
                            this.mainOverlay = false;
                        }
                        if (this.hasQueryFilter) {
                            let filters = __clone(this.queryFilters);
                            this.queryFilters = {
                                category: [],
                                product: [],
                                warehouse: [],
                                readiness: [],
                                characteristics: []
                            };
                            this.queryFiltering = false;
                            this.toggleFilters(filters);
                            this.applyFilters();
                        } else {
                            this.$scrollTop();
                        }
                        this.fetchTime = Date.now() - fetchStartTime;
                        resolve();
                    })
                    .catch(() => {
                        this.$snackBar({
                            icon: 'mdi-alert',
                            text: this.$t('components.stocklist.notready')
                        })
                    })
                    .finally(() => this.loadingOverlay = false);
            });
        },

        clearFilters() {
            this.filters.product.map(item => item.checked = false);
            this.filters.characteristics.map(item => item.values.map(char => char.checked = false));
            this.bootstrap();
        },

        applyFilters() {
            let filters = {
                product: [],
                characteristics: {},
                readiness: [],
                warehouse: [],
            };
            this.filters.product.map(item => {
                if (item.checked) {
                    filters.product.push(item.value);
                }
            });

            this.filters.warehouse.map(item => {
                if (item.checked) {
                    filters.warehouse.push(item.value);
                }
            });

            this.filters.readiness.map(item => {
                if (item.checked) {
                    filters.readiness.push(item.value);
                }
            });

            this.filters.characteristics.map(char => {
                char.values.map(item => {
                    if (item.checked) {
                        if (typeof filters.characteristics[char.characteristic_id] === 'undefined') {
                            filters.characteristics[char.characteristic_id] = [];
                        }
                        filters.characteristics[char.characteristic_id].push(item.option_value_id);
                    }
                })
            });

            this.bootstrap(filters);
        },

        toggleFilters(filters) {
            filters.product.map(x => {
                let product = this.filters.product.find(item => item.value === x);
                if (product) {
                    product.checked = true;
                }
            });

            for (const [key, value] of Object.entries(filters.characteristics)) {
                let char = this.filters.characteristics.find(item => item.characteristic_id === key);
                if (char) {
                    value.forEach(val => {
                        let opt = char.values.find(item => item.option_value_id === val);
                        if (opt) {
                            opt.checked = true;
                        }
                    });
                }
            }

        },

        removeItem(container, identifier) {
            this.stock.forEach((item, index) => {
                if (container === 'shopord') {
                    let shopOrdItemIndex = item.shopord.findIndex(x => x.uniqueId === identifier);
                    if (shopOrdItemIndex !== -1) {
                        this.$set(this.stock[index].shopord[shopOrdItemIndex], 'hidden', true);
                    }
                } else if(container === 'purchaseorder') {
                    let purchOrdItemIndex = item.purchaseorder.findIndex(x => x.uniqueId === identifier);
                    if (purchOrdItemIndex !== -1) {
                        this.$set(this.stock[index].purchaseorder[purchOrdItemIndex], 'hidden', true);
                    }
                } else {
                    this.$set(this.stock[index].stocklist[identifier], 'hidden', true);
                }
            });
        },

        decrementStockQuantity(catalogNo, configNo, warehouse, value) {
            return new Promise(resolve => {
                let index = this.stock.findIndex(item => item.catalogNo === catalogNo);
                if (index !== -1) {
                    let quantity = this.stock[index].stocklist[`${configNo}${warehouse}`].quantity - value;
                    this.$set(this.stock[index].stocklist[`${configNo}${warehouse}`], 'quantity', quantity);
                }
                resolve();
            });
        },

        decrementPurchaseOrderQuantity(catalogNo, productIndex, value) {
            return new Promise(resolve => {
                let index = this.stock.findIndex(item => item.catalogNo === catalogNo);
                if (index !== -1) {
                    let quantity = this.stock[index].purchaseorder[productIndex].quantity - value;
                    this.$set(this.stock[index].purchaseorder[productIndex], 'quantity', quantity);
                }
                resolve();
            });
        },

        resolveApiService() {
            return PurchaseOrderApi;
        }
    },

    computed: {
        products() {
            return this.stock;
        },

        filteredResults() {
            let items = [];
            this.products.forEach(item => {
                if (!empty(this.quickSearch)) {
                    let r = new RegExp(this.quickSearch, "iu");
                    if (r.test(item.name) || r.test(item.catalogNo)) {
                        items.push(item);
                    }
                } else {
                    items.push(item);
                }
            });

            items.sort((a, b) => a.name > b.name);

            return items;
        },

        productFilters() {
            return this.filters.product;
        },

        warehouseFilters() {
            return this.filters.warehouse;
        },

        characteristicFilters() {
            return this.filters.characteristics;
        },

        readinessFilters() {
            return this.filters.readiness;
        },

        dataLength() {
            let len = 0;
            this.stock.forEach(item => {
                len += item.shopord.length;
                Object.values(item.stocklist).forEach(config => len += config.quantity);
            });
            return len;
        },

        fetchTimeComputed() {
            return (Math.abs(this.fetchTime) / 1000).toFixed(2);
        },

        hasQueryFilter() {
            return this.queryFiltering;
        }
    }
}
</script>

<style scoped>

</style>
