<template>
    <div>
        <v-dialog
            v-model="visible"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    dark
                    color="wellis"
                >
                    <v-btn
                        icon
                        dark
                        @click="close"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{name}} / {{catalogNo}} <small v-if="$store.getters['authStore/currentRealm'] === 'wellis_usa_realm'">
                        {{$t('v32.components.sale.stockInEurope')}}</small>
                        <small v-else>{{$t('components.shopord.title')}}</small>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-5">
                    <v-overlay :value="loadingOverlay" absolute color="white">
                        <v-progress-circular
                            color="wellis"
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                    <v-row>
                        <v-responsive>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            {{$t('components.shopord.needweek') | capitalize}}
                                        </th>
                                        <th>{{$t('components.stocklist.configNoShort') | capitalize}}</th>
                                        <th>{{$t('components.products.quantity')}}</th>
                                        <th colspan="2">{{$t('components.products.price')}}</th>
                                        <th>{{$t('components.stocklist.warehouse')}}</th>
                                        <th>{{$t('components.stocklist.acrylicColor')}}</th>
                                        <th>{{$t('components.stocklist.cabinetColor')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in items" :key="item.uniqueId">
                                        <td width="10%">
                                            <v-btn v-if="item.hidden !== true && quantity[index] > 0" @click="addCart(item, index)">
                                                {{$t('components.products.availability.btnAddCart')}}
                                            </v-btn>
                                        </td>
                                        <td>
                                            {{$t('components.shopord.readyWeek', {num: item.needWeek})}}
                                        </td>
                                        <td>
                                            <v-btn text
                                                   @click="viewConfiguration(item.configNo)">
                                                {{item.configNo}}
                                                <v-icon class="ml-2">mdi-cog</v-icon>
                                            </v-btn>
                                        </td>
                                        <td>
                                            <v-text-field v-model="quantity[index]" type="number"
                                                          style="max-width: 175px"
                                                          color="wellis"
                                                          class="font-weight-bold"
                                                          :disabled="quantity[index] < 1"
                                                          append-outer-icon="mdi-plus" @click:append-outer="incrementQuantity(index)"
                                                          prepend-icon="mdi-minus" @click:prepend="decrementQuantity(index)"></v-text-field>
                                        </td>
                                        <td v-if="quantity[index] > 0">
                                            <div v-if="hasPrice(item)" class="wellis--text font-weight-bold">
                                                <small class="d-block black--text font-weight-light" v-if="item.price.vat">
                                                    {{item.price.unitPrice * quantity[index] | toPrice(item.price.currency, false)}} + {{$t('components.products.vat')}}
                                                </small>
                                                {{item.price.unitPrice * quantity[index] | toPrice(item.price.currency, item.price.vat)}}
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </td>
                                        <td v-else>-</td>
                                        <td>
                                            <div v-if="hasPrice(item)" class="wellis--text font-weight-bold">
                                                <small class="d-block black--text font-weight-light" v-if="item.price.vat">
                                                    {{item.price.unitPrice * 1 | toPrice(item.price.currency, false)}} + {{$t('components.products.vat')}}
                                                </small>
                                                {{item.price.unitPrice * 1 | toPrice(item.price.currency, item.price.vat)}}
                                            </div>
                                            <div v-else>
                                                -
                                            </div>
                                        </td>
                                        <td>{{item.warehouse}}</td>
                                        <td>{{item.acrylicColor}}</td>
                                        <td>{{item.cabinetColor}}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-responsive>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            :value="configurationDialog"
            max-width="650px"
            @click:outside="configurationDialog = false"
        >
            <v-card>
                <v-card-title>
                    {{configurationData.configNo}}
                </v-card-title>
                <v-card-text>
                    <v-row v-for="item in configurationData.items" :key="item.characteristic_id" dense>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold">{{item.characteristic_id}}</v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" class="font-weight-bold wellis--text">{{item.characteristic_value}}</v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CartApiService from '../../../api/cart.api';
import {EVENT_CART_COUNT_UPDATE} from '../../../core/constants';
import PurchaseOrderApi from '../../../api/purchase-order.api';
import ConfiguratorApiService from '../../../api/configurator.api.service';
import {LiteUser} from '../../../helpers';

export default {
    name: 'SaleShopOrdDialog',

    props: {
        visible: Boolean,
        name: String,
        catalogNo: String,
        items: Array,
    },

    mounted() {
        this.mapQuantities();
    },

    data() {
        return {
            loadingOverlay: false,
            configurationDialog: false,
            configurationData: {
                configNo: null,
                items: [],
            },

            quantity: [],
        };
    },

    methods: {
        close() {
            this.$emit('close');
        },

        addCart(item, productIndex) {
            if (!LiteUser.hasPermission(
                this.$store.getters['authStore/isLiteUser'],
                this.$store.getters['authStore/getLiteUserPermission'],
                'place_order',
            )) {
                this.$ApplGeneral({
                    content: this.$t('v32.liteUser.hasNoPermission'),
                });
                return;
            }

            this.loadingOverlay = true;
            let attributes = {
                'coordinator': 'addProductConfiguration',
                'product': item.uniqueId,
                'quantity': this.quantity[productIndex],
                'host': this.resolveHost,
                'configurations': {},
            };

            CartApiService.add(attributes)
                .then(response => {
                    this.$eventBus.$emit(EVENT_CART_COUNT_UPDATE, response.data.count);
                    this.$SaleView.decrementPurchaseOrderQuantity(this.catalogNo, productIndex, this.quantity[productIndex])
                        .then(() => {
                            this.mapQuantities();
                            this.$forceUpdate();
                        });
                })
                .catch(error => {
                    if (error.response.data.exception) {
                        if (error.response.data.exception.class === 'DifferentWarehouseException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-warehouse'),
                                icon: 'mdi-alert',
                            });
                        }

                        if (error.response.data.exception.class === 'DifferentStocklistTypeException') {
                            this.$snackBar({
                                content: this.$t('components.cart.different-stocklist-type'),
                                icon: 'mdi-alert',
                            });
                        }

                        if (error.response.data.exception.class === 'UnavailableQuantityException') {
                            this.$snackBar({
                                content: this.$t('components.cart.unavailable-quantity'),
                                icon: 'mdi-alert',
                            });
                        }
                    } else {
                        this.$snackBar({
                            content: this.$t('components.cart.add-cart-error'),
                            icon: 'mdi-alert',
                        });
                    }
                })
                .finally(() => this.loadingOverlay = false);
        },

        viewConfiguration(configNo) {
            this.loadingOverlay = true;
            ConfiguratorApiService.getConfiguration(configNo)
                .then(response => {
                    this.configurationData.configNo = configNo;
                    this.configurationData.items = Object.values(response.data.configuration);
                    this.configurationDialog = true;
                })
                .finally(() => this.loadingOverlay = false);
        },

        findConfigurationValueDesc(item) {
            let value = item.values.find(x => item.selectedValue === x.optionValueId);
            return value ? value.description : item.selectedValue;
        },

        resolveApiService() {
            return PurchaseOrderApi;
        },

        incrementQuantity(index) {
            this.$set(this.quantity, index, this.quantity[index] + 1);
            if (this.quantity[index] > this.items[index].quantity) {
                this.$set(this.quantity, index, this.items[index].quantity);
            }
        },

        decrementQuantity(index) {
            this.$set(this.quantity, index, this.quantity[index] - 1);
            if (this.quantity[index] < 1) {
                this.$set(this.quantity, index, 1);
            }
        },

        mapQuantities() {
            this.quantity = this.items.map(item => item.quantity);
        },
    },

    computed: {
        hasPrice() {
            return item => {
                return item.price && typeof item.price !== 'undefined';
            };
        },

        resolveHost() {
            return 'PURCHORD';
        },
    },

    watch: {
        'visible': function(visible) {
            if (visible) {
                this.mapQuantities();
            }
        },
    },
};
</script>

<style scoped>

</style>
